// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiPaper-root
    .MuiPaper-elevation
    .MuiPaper-rounded
    .MuiPaper-elevation6
    .MuiCard-root
    .css-1u7jyyi-MuiPaper-root-MuiCard-root {
    overflow: auto;
}
body {
    overflow: auto;
}
`, "",{"version":3,"sources":["webpack://./src/app/App.css"],"names":[],"mappings":"AAAA;;;;;;IAMI,cAAc;AAClB;AACA;IACI,cAAc;AAClB","sourcesContent":[".MuiPaper-root\n    .MuiPaper-elevation\n    .MuiPaper-rounded\n    .MuiPaper-elevation6\n    .MuiCard-root\n    .css-1u7jyyi-MuiPaper-root-MuiCard-root {\n    overflow: auto;\n}\nbody {\n    overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
