
import axios from "axios";
import { BACKEND_URL } from "config";

const apiClient = axios.create({
    baseURL: BACKEND_URL, // Optional: set the base URL
});

// Request interceptor to automatically add the token to every request
apiClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Token refresh interceptor
apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // If access token is expired
        /*if (error.response.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true;

            const refreshToken = localStorage.getItem('refreshToken');
            if (!refreshToken) {
                return Promise.reject(error);
            }

            try {
                // Request a new access token using the refresh token
                const response = await axios.post('/api/refresh-token', { refreshToken });
                const { accessToken } = response.data;

                // Store the new access token
                localStorage.setItem('token', accessToken);

                // Update Authorization header in original request and retry
                originalRequest.headers['Authorization'] = `${accessToken}`;

                return apiClient(originalRequest); // Retry the original request
            } catch (refreshError) {
                console.error('Error refreshing token', refreshError);
                return Promise.reject(refreshError);
            }
        }*/

        // Handle 401 Unauthorized error globally
        if (error.response.status === 401) {
            console.error('Unauthorized! Please log in again.');
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            window.location.reload();
        }

        return Promise.reject(error);
    }
);

export default apiClient;